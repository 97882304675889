import React, { ChangeEventHandler } from "react"

import styled from "@emotion/styled"

const CheckboxContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  margin: "1.5rem 0 0.5rem 0",

  "& ~ &": {
    margin: "0 0 0.5rem 0",
  },

  "input:checked ~ span": {
    backgroundColor: "#6d8a96",
  },

  "span:after": {
    border: "solid white",
    borderWidth: "0 3px 3px 0",
    content: "''",
    display: "none",
    height: "10px",
    left: "9px",
    position: "absolute",
    top: "5px",
    transform: "rotate(45deg)",
    width: "5px",
  },

  "input:checked ~ span:after": {
    display: "block",
  },
})

const CheckboxLabel = styled.label({
  cursor: "pointer",
  display: "block",
  fontSize: "22px",
  marginBottom: "12px",
  position: "relative",
  paddingLeft: "35px",

  ":hover input ~ span": {
    backgroundColor: "#ccc",
  },
})

const CheckboxInput = styled.input({
  cursor: "pointer",
  height: 0,
  opacity: 0,
  position: "absolute",
  width: 0,
})

const CheckboxCheck = styled.span({
  backgroundColor: "#eee",
  borderRadius: "50%",
  height: "25px",
  left: 0,
  position: "absolute",
  top: "7.5px",
  width: "25px",
})

interface CheckboxProps {
  checked: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
  text: string
}

const Checkbox = ({ checked, onChange, text }: CheckboxProps) => (
  <CheckboxContainer>
    <CheckboxLabel>
      {text}
      <CheckboxInput type="checkbox" checked={checked} onChange={onChange} />
      <CheckboxCheck />
    </CheckboxLabel>
  </CheckboxContainer>
)

export default Checkbox
