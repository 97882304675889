import styled from "@emotion/styled"
import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import { mq } from "./styles"

const TimelineContainer = styled.ul({
  padding: 0,
  width: "100%",

  [mq[0]]: {
    padding: "unset",
    width: "unset",
  },
})

const Content = styled.div({
  background: "#34995b",
  padding: "20px",
  position: "relative",
  top: "9px",
  width: "calc(80vw - 100px)",

  ":before": {
    background: "#34995b",
    content: "''",
    height: "5px",
    position: "absolute",
    top: "0px",
    width: "38px",
  },

  [mq[0]]: {
    width: "41vw",
  },

  [mq[1]]: {
    width: "350px",
  },
})

const Dot = styled.li({
  background: "#34995b",
  listStyleType: "none",
  marginLeft: "20px",
  paddingBottom: "40px",
  position: "relative",
  width: "5px",

  ":last-child": {
    paddingBottom: "9px",
  },

  ":before": {
    background: "#faf8eb",
    border: "3px solid #34995b",
    borderRadius: "50%",
    content: "''",
    height: "20px",
    left: "50%",
    position: "absolute",
    top: 0,
    transform: "translateX(-50%)",
    width: "20px",
  },

  ":nth-of-type(even)": {
    [Content]: {
      background: "linear-gradient(-45deg, #56bc83, #34995b)",
      left: "52px",

      ":before": {
        left: "-38px",
      },

      [mq[0]]: {
        background: "linear-gradient(45deg, #56bc83, #34995b)",
        left: "calc(-41vw - 45px)",

        ":before": {
          left: "unset",
          right: "-38px",
        },
      },

      [mq[1]]: {
        left: "calc(-350px - 86px)",
      },
    },
  },

  ":nth-of-type(odd)": {
    [Content]: {
      background: "linear-gradient(-45deg, #56bc83, #34995b)",
      left: "52px",

      ":before": {
        left: "-38px",
      },
    },
  },

  [mq[0]]: {
    margin: "0 auto",
  },
})

const Date = styled.h2({
  color: "#fff",
  margin: "0 0 10px 0",
  textAlign: "initial",

  [mq[0]]: {
    textAlign: "center",
  },
})

const Caption = styled.p({
  color: "#fff",
  margin: "15px 0 0 0",
  textAlign: "center",
})

const timelineImageStyles = css({
  userSelect: "none",
  width: "100%",
})

const Timeline = () => (
  <TimelineContainer>
    <Dot>
      <Content>
        <Date><time>2009</time></Date>
        <StaticImage
          src="../../static/images/2009.jpg"
          css={timelineImageStyles}
          alt="2009"
          title="2009"
          placeholder="blurred"
          layout="fullWidth"
          loading="lazy"
          formats={['auto', 'webp', 'avif']}
        />
        <Caption>Hanging out during lunch</Caption>
      </Content>
    </Dot>
    <Dot>
      <Content>
        <Date><time>2010</time></Date>
        <StaticImage
          src="../../static/images/2010.jpg"
          css={timelineImageStyles}
          alt="2010"
          title="2010"
          placeholder="blurred"
          layout="fullWidth"
          loading="lazy"
          formats={['auto', 'webp', 'avif']}
        />
        <Caption>Islands of Adventure</Caption>
      </Content>
    </Dot>
    <Dot>
      <Content>
        <Date><time>2011</time></Date>
        <StaticImage
          src="../../static/images/2011.jpg"
          css={timelineImageStyles}
          alt="2011"
          title="2011"
          placeholder="blurred"
          layout="fullWidth"
          loading="lazy"
          formats={['auto', 'webp', 'avif']}
        />
        <Caption>Courtney's Prom</Caption>
      </Content>
    </Dot>
    <Dot>
      <Content>
        <Date><time>2012</time></Date>
        <StaticImage
          src="../../static/images/2012.jpg"
          css={timelineImageStyles}
          alt="2012"
          title="2012"
          placeholder="blurred"
          layout="fullWidth"
          loading="lazy"
          formats={['auto', 'webp', 'avif']}
        />
        <Caption>Jacksonville for Chloe's 22nd</Caption>
      </Content>
    </Dot>
    <Dot>
      <Content>
        <Date><time>2013</time></Date>
        <StaticImage
          src="../../static/images/2013.jpg"
          css={timelineImageStyles}
          alt="2013"
          title="2013"
          placeholder="blurred"
          layout="fullWidth"
          loading="lazy"
          formats={['auto', 'webp', 'avif']}
        />
        <Caption>4yr Anniversary</Caption>
      </Content>
    </Dot>
    <Dot>
      <Content>
        <Date><time>2014</time></Date>
        <StaticImage
          src="../../static/images/2014.jpg"
          css={timelineImageStyles}
          alt="2014"
          title="2014"
          placeholder="blurred"
          layout="fullWidth"
          loading="lazy"
          formats={['auto', 'webp', 'avif']}
        />
        <Caption>New Years</Caption>
      </Content>
    </Dot>
    <Dot>
      <Content>
        <Date><time>2015</time></Date>
        <StaticImage
          src="../../static/images/2015.jpg"
          css={timelineImageStyles}
          alt="2015"
          title="2015"
          placeholder="blurred"
          layout="fullWidth"
          loading="lazy"
          formats={['auto', 'webp', 'avif']}
        />
        <Caption>Brock and Misty Halloween</Caption>
      </Content>
    </Dot>
    <Dot>
      <Content>
        <Date><time>2016</time></Date>
        <StaticImage
          src="../../static/images/2016.jpg"
          css={timelineImageStyles}
          alt="2016"
          title="2016"
          placeholder="blurred"
          layout="fullWidth"
          loading="lazy"
          formats={['auto', 'webp', 'avif']}
        />
        <Caption>New Tampa Nature Park</Caption>
      </Content>
    </Dot>
    <Dot>
      <Content>
        <Date><time>2017</time></Date>
        <StaticImage
          src="../../static/images/2017.jpg"
          css={timelineImageStyles}
          alt="2017"
          title="2017"
          placeholder="blurred"
          layout="fullWidth"
          loading="lazy"
          formats={['auto', 'webp', 'avif']}
        />
        <Caption>Nashville - Mario's first flight</Caption>
      </Content>
    </Dot>
    <Dot>
      <Content>
        <Date><time>2018</time></Date>
        <StaticImage
          src="../../static/images/2018.jpg"
          css={timelineImageStyles}
          alt="2018"
          title="2018"
          placeholder="blurred"
          layout="fullWidth"
          loading="lazy"
          formats={['auto', 'webp', 'avif']}
        />
        <Caption>Cruisin' in Cuba</Caption>
      </Content>
    </Dot>
    <Dot>
      <Content>
        <Date><time>2019</time></Date>
        <StaticImage
          src="../../static/images/2019.jpg"
          css={timelineImageStyles}
          alt="2019"
          title="2019"
          placeholder="blurred"
          layout="fullWidth"
          loading="lazy"
          formats={['auto', 'webp', 'avif']}
        />
        <Caption>Queen Concert</Caption>
      </Content>
    </Dot>
    <Dot>
      <Content>
        <Date><time>2020</time></Date>
        <StaticImage
          src="../../static/images/2020.jpg"
          css={timelineImageStyles}
          alt="2020"
          title="2020"
          placeholder="blurred"
          layout="fullWidth"
          loading="lazy"
          formats={['auto', 'webp', 'avif']}
        />
        <Caption>Proposed in Hillsborough River State Park on Feb 29</Caption>
      </Content>
    </Dot>
  </TimelineContainer>
)

export default Timeline
