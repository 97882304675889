import styled from "@emotion/styled"
import React from "react"
import TextContainer from "./text_container"
import TitleContainer from "./title_container"
import Section from "./section"
import { mq } from "./styles"
import { StaticImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"

const VenueLink = styled.a({
  marginLeft: "0.3rem",
})

const Map = styled.iframe({
  border: 0,
  height: 225,
  marginTop: "1rem",
  width: 300,

  [mq[1]]: {
    height: 450,
    width: 600,
  },
})

const imageStyles = css({
  boxShadow: "0 3px 8px 0 rgba(0,0,0,0.4), 0 0 0 1px rgba(0,0,0,0.16)",
  borderRadius: 4,
  marginTop: "1rem",
  userSelect: "none",
  width: 300,

  [mq[1]]: {
    width: 600,
  },
})

interface VenueProps {
  gmaps: string
}

const Venue = ({ gmaps }: VenueProps) => (
  <Section>
    <TitleContainer basis="100%" id="venue" text="VENUE" to="#venue" />

    <TextContainer basis="100%">
      <span>
        We booked Tampa Bay Watch as our venue!! A nonprofit whose mission is to protect Florida's Estuaries.
      </span>
    </TextContainer>
    <TextContainer basis="100%">
      <span>
        To learn more click
        <VenueLink
          href="https://tampabaywatch.org/"
          title="Tampa Bay Watch"
          target="_blank"
        >here</VenueLink>. The map/address is below the venue photo!
      </span>
    </TextContainer>

    <StaticImage
      src="../../static/images/tbw.jpg"
      css={imageStyles}
      alt="Tampa Bay Watch overhead view"
      title="Tampa Bay Watch overhead view"
      placeholder="blurred"
      layout="fullWidth"
      loading="lazy"
      formats={['auto', 'webp', 'avif']}
    />

    <Map
      src={gmaps}
      allowFullScreen={false}
      loading="lazy" />
  </Section>
)

export default Venue
