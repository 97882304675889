import styled from "@emotion/styled"
import React, { ReactNode } from "react"

const ContainerStyled = styled.div({
  display: "flex",
  justifyContent: "center",
}, props => ({ flexWrap: props.wrap }))

interface ContainerProps {
  children: ReactNode
  wrap: string
}

const Container = ({ wrap, children }: ContainerProps) => (
  <ContainerStyled wrap={wrap}>
    {children}
  </ContainerStyled>
)

Container.defaultProps = {
  wrap: "initial",
}

export default Container
