import React from "react"
import { StaticQuery, graphql } from "gatsby"

import styled from "@emotion/styled"
import { mq } from "../components/styles"

import Layout from "../components/layout"
import CM from "../components/cm"
import RSVPForm from "../components/rsvp_form"
import Hotel from "../components/hotel"
import Venue from "../components/venue"
import Warning from "../components/warning"

const Container = styled.div({
  flexGrow: 1,
  flexShrink: 1,
  margin: "0 auto",

  [mq[1]]: {
    maxWidth: 960,
    width: 960,
  },

  [mq[2]]: {
    maxWidth: 1152,
    width: 1152,
  },

  [mq[3]]: {
    maxWidth: 1344,
    width: 1344,
  },
})

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query LayoutButtonsQuery {
        site {
          siteMetadata {
            slogan
            hotelMap
            venueMap
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Container>
          <Warning>
            For the safety of our family and friends, all those attending must be fully vaccinated for COVID-19.
          </Warning>
          <CM />
          <RSVPForm />
          <Venue gmaps={data.site.siteMetadata.venueMap} />
          <Hotel gmaps={data.site.siteMetadata.hotelMap} />
        </Container>
      </Layout>
    )}
  />
)

export default IndexPage
