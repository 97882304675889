import styled from "@emotion/styled"
import React from "react"
import TextContainer from "./text_container"
import TitleContainer from "./title_container"
import Section from "./section"
import { mq } from "./styles"

const HotelLink = styled.a({
  marginLeft: "0.3rem",
})

const Map = styled.iframe({
  border: 0,
  height: 225,
  marginTop: "1rem",
  width: 300,

  [mq[1]]: {
    height: 450,
    width: 600,
  },
})

interface HotelProps {
  gmaps: string
}

const Hotel = ({ gmaps }: HotelProps) => (
  <Section>
    <TitleContainer basis="100%" id="hotel" text="HOTEL" to="#hotel" />

    <TextContainer basis="100%">
      <span>
        We reserved a block of rooms at the hotel below! Click
        <HotelLink
          href="https://www.marriott.com/events/start.mi?id=1622758542241&key=GRP"
          title="reserve hotel"
          target="_blank"
        >here</HotelLink> to reserve a room.
      </span>
    </TextContainer>

    <TextContainer basis="100%">
      Book by October 12, 2021
    </TextContainer>

    <Map
      src={gmaps}
      allowFullScreen={false}
      loading="lazy" />
  </Section>
)

export default Hotel
