import React, { useState } from "react"

import ReCAPTCHA from "react-google-recaptcha"
import styled from "@emotion/styled"
import { colors } from "./styles"
import Checkbox from "./checkbox"
import Container from "./container"
import TextContainer from "./text_container"
import TitleContainer from "./title_container"
import Warning from "./warning"

import { css } from "@emotion/react"

const Field = styled.input({
  appearance: "none",
  display: "block",
  outline: 0,
  border: "1px solid rgba(255, 255, 255, 0.45)",
  backgroundColor: "rgba(255, 255, 255, 0.3)",
  width: "250px",
  borderRadius: "3px",
  padding: "10px 15px",
  margin: "1rem auto 10px auto",
  textAlign: "center",
  fontSize: "18px",
  color: "white",
  transitionDuration: "0.25s",
  fontWeight: 300,

  ":hover": {
    backgroundColor: "rgba(255, 255, 255, 0.45)",
  },

  ":focus": {
    backgroundColor: "white",
    width: "300px",

    color: colors.buttonTextColor,
  },
})

const Button = styled.button({
  backgroundColor: "white",
  border: "1px solid white",
  borderRadius: "3px",
  color: colors.buttonTextColor,
  cursor: "pointer",
  display: "block",
  fontSize: "18px",
  margin: "0 auto",
  outline: 0,
  padding: "10px 15px",
  transitionDuration: "0.25s",
  width: "282px",

  ":active, :hover": {
    backgroundColor: colors.buttonBgColor,
  },

  ":disabled": {
    backgroundColor: colors.buttonBgColor,
    cursor: "not-allowed",
  }
})

const captchaStyles = css({
  display: "flex",
  justifyContent: "center",
  marginBottom: "0.5rem",
})

const RSVPForm = () => {
  const [code, setCode] = useState('')
  const [showRSVP, setShowRSVP] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [rsvps, setRSVPs] = useState({
    peeps: [],
    none: false,
  })
  const [noneAttending, setNoneAttending] = useState(false)
  const [submitIsDisabled, setSubmitDisabled] = useState(true)
  const [token, setToken] = useState('')
  const workerUrl = process.env.WORKER_URL || 'https://blopez-rsvp-production.techmunchies.workers.dev'

  const onToken = v => {
    setToken(v)
    setSubmitDisabled(false)
  }

  const handleCodeSubmit = e => {
    e.preventDefault()
    const body = {code: code}

    fetch(`${workerUrl}/rsvp/get`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "g-recaptcha": `${token}`,
      },
      body: JSON.stringify(body)
    })
    .then(res => {
      const contentType = res.headers.get("content-type")

      if (contentType && contentType.indexOf("application/json") !== -1) {
        res.json().then(json => {
          setRSVPs(JSON.parse(json))
          setNoneAttending(rsvps.none)
          setShowRSVP(true)
        })
      }
    })
    .catch(err => {
      console.log(`unexpected err: ${err}`)
    })
  }

  const handleRSVPSubmit = e => {
    e.preventDefault()

    rsvps.none = noneAttending
    const body = {code: code, value: rsvps}

    fetch(`${workerUrl}/rsvp/set`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    .then(res => {
      if (res.status == 200) {
        setShowSuccess(true)
      } else {
        console.log(`unexpected err: ${res.body}`)
      }
    })
    .catch(err => {
      console.log(`unexpected err: ${err}`)
    })
  }

  return (
    <>
      <TitleContainer id="rsvp" text="RSVP" to="#rsvp" />

      { ! showRSVP ?
        <Container>
          <TextContainer>
            Please enter the code provided with your invite in the box below
          </TextContainer>
        </Container>
      : ! showSuccess ?
        <Container>
          <TextContainer>
            Please select all those attending
          </TextContainer>
        </Container>
        : null }

      { ! showSuccess ?
      <>
        <Container>
          <TextContainer>
            RSVP by October 1, 2021. You can update your RSVP at any time before then
          </TextContainer>
        </Container>
        <Container>
          <Warning>
            For the safety of our family and friends, by submitting your RSVP you certify that those attending are fully vaccinated for COVID-19.
          </Warning>
        </Container>
      </>
      : null }

      { showSuccess ?
        <Container>
          <TextContainer css={{padding: "0 1.25rem 1rem 1.25rem"}}>
            Thanks for your RSVP!
          </TextContainer>
        </Container>
      : null }

      { ! showRSVP && ! showSuccess ?
      <form onSubmit={handleCodeSubmit}>
        <Field
          type="text"
          placeholder="enter code"
          value={code}
          onChange={e => setCode(e.target.value.toLowerCase())} />
        <ReCAPTCHA
          css={captchaStyles}
          sitekey="6LdIVaQbAAAAABEVG2ZmPqUASXjpAkJfG9C_-UaH"
          onChange={onToken}
        />
        <Button
          title="Submit"
          type="submit"
          disabled={submitIsDisabled}
        >
          Submit
        </Button>
      </form>
      : null }
      { showRSVP && ! showSuccess ?
      <form onSubmit={handleRSVPSubmit}>
        {rsvps.peeps.map(peep =>
          <Checkbox
            id={peep.name}
            text={peep.name}
            checked={peep.coming}
            onChange={e => {
              setRSVPs(prevState => ({
                peeps: prevState.peeps.map(
                  el => el.name === peep.name
                    ? {...el, coming: e.target.checked ? 1 : 0 }
                    : el
                )
              }))
            }}
          />
        )}
        { rsvps.peeps.every(peep => peep.coming === 0) ?
          <Checkbox
            id="none-attending"
            text="No Guests Attending"
            checked={noneAttending || rsvps.none}
            onChange={e => setNoneAttending(e.target.checked)}
          />
        : null }
        <input
            type="text"
            value={code}
            readOnly
            hidden />
        <Button title="Submit" type="submit">RSVP</Button>
      </form>
      : null }
    </>
  )
}

export default RSVPForm
