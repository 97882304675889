import React from "react"
import TitleContainer from "./title_container"
import Section from "./section"
import Timeline from "./timeline"

const CM = () => (
  <Section>
    <TitleContainer basis="100%" id="cnm" text="C&M" to="#cnm" />
    <Timeline/>
  </Section>
)

export default CM
