import styled from "@emotion/styled"
import React, { ReactNode } from "react"
import Container from "./container"

const SectionStyles = styled.div({
  margin: "2rem auto",
})

interface SectionProps {
  children: ReactNode
}

const Section = ({ children }: SectionProps) => (
  <SectionStyles>
    <Container wrap="wrap">
      {children}
    </Container>
  </SectionStyles>
)

export default Section
