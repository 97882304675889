import styled from "@emotion/styled"
import React, { ReactNode } from "react"
import { colors } from "./styles"

const Container = styled.span({
  color: colors.textcolor,
  display: "flex",
  fontSize: "1rem",
  fontWeight: 300,
  justifyContent: "center",
  letterSpacing: 1.2,
  lineHeight: 1.2,
  padding: "0.5rem 1.25rem 0 1.25rem",
  position: "relative",
}, props => ({ flexBasis: props.basis }))

interface TextContainerProps {
  basis: string
  children: ReactNode
}

const TextContainer = ({ basis, children }: TextContainerProps) => (
  <Container basis={basis}>
    {children}
  </Container>
)

TextContainer.defaultProps = {
  basis: "initial",
}

export default TextContainer
