import React from "react"

import styled from "@emotion/styled"
import { Link } from "gatsby"
import { colors } from "./styles"

const Container = styled.div({
  display: "flex",
  justifyContent: "center",
}, props => ({ flexBasis: props.basis }))

const Title = styled(Link)({
  color: colors.textcolor,
  cursor: "pointer",
  display: "flex",
  fontSize: "1.7rem",
  fontWeight: 200,
  letterSpacing: 1.2,
  lineHeight: 1.2,
  padding: "1rem 1.25rem",
  position: "relative",
  textDecoration: "none",
})

interface TitleContainerProps {
  basis: string
  id: string
  text: string
  to: string
}

const TitleContainer = ({ basis, id, text, to }: TitleContainerProps) => (
  <Container basis={basis}>
    <Title id={id} to={to}>{text}</Title>
  </Container>
)

TitleContainer.defaultProps = {
  basis: "initial",
}

export default TitleContainer
