import styled from "@emotion/styled"
import React, { ReactNode } from "react"

const Container = styled.span({
  backgroundColor: "#ff9800",
  color: "white",
  display: "flex",
  fontSize: "1rem",
  fontWeight: 600,
  justifyContent: "center",
  letterSpacing: 1.2,
  lineHeight: 1.2,
  margin: "1rem 0",
  padding: "1rem 1.25rem",
  position: "relative",
})

interface WarningProps {
  children: ReactNode
}

const Warning = ({ children }: WarningProps) => (
  <Container>
    {children}
  </Container>
)

export default Warning
